import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "spotting-signs-of-stress"
    }}>{`Spotting signs of stress`}</h1>
    <p>{`The key to teaching your teenager stress management skills is to pay attention to your teenager’s behaviour for signs that they may not be coping well with stress.`}</p>
    <p>{`Here are some signs that your teenager may be stressed out or overloaded:`}</p>
    <ul>
      <li parentName="ul">{`Complaints of headaches, stomach-aches, muscle pain and tiredness`}</li>
      <li parentName="ul">{`Withdrawing from people and activities`}</li>
      <li parentName="ul">{`Increased anger or irritability`}</li>
      <li parentName="ul">{`Crying more often or being teary`}</li>
      <li parentName="ul">{`Changes in sleeping and eating habits`}</li>
      <li parentName="ul">{`Difficulty concentrating`}</li>
      <li parentName="ul">{`Worrying or fretting more than usual.`}</li>
    </ul>
    <p>{`If you see that your teenager is not coping well with stress, gently discuss with them some stress management
strategies that they might find helpful. See `}<GoTo to="/m8-problems/08-stress-management" mdxType="GoTo">{`Stress management skills`}</GoTo>{`.`}</p>
    <p>{`If your teenager continues to struggle with stress or is unable to move on from stressful experiences, get help for them from a mental health professional (check out our `}<GoTo to="/resources" mdxType="GoTo">{`Useful Resources`}</GoTo>{` page after you finish this module). See the module `}<GoTo to="/dashboard" mdxType="GoTo"><i>{`From surviving to thriving: Helping your teenager deal with anxiety`}</i></GoTo>{` for some useful tips.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m8/41-01.svg" alt="Sad Teen" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      